/* eslint-disable jsdoc/require-param */
/**
 * @module PlansComponent
 */
import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { activeOrgIdSelector, viewingAllOrgsSelector } from 'state/selectors'
import { Loader } from 'components'
import { PlansHeading } from 'components/Plans/PlansHeading'
import styles from 'components/Plans/Plans.module.scss'
import { State } from 'state/reducers'
import { useGetPlans } from 'api/plans'
import { PLANS_PER_PAGE } from 'helpers'
import NewOrganization from 'containers/landing-page/new-organization'
import LandingPage from 'containers/landing-page'

interface PlansComponentProps {
  /** The active organization id. */
  organizationId: string
  /** Indicates if the active organization is "All Organizations". */
  isViewingAllOrgs: boolean
}

/**
 * The plans page.
 *
 * @returns {ReactElement} - The Plans component.
 */
export function PlansComponent({
  organizationId,
  isViewingAllOrgs,
}: PlansComponentProps) {
  const [query] = useSearchParams()

  const { data: plansResponse, status } = useGetPlans({
    filters: {
      language: query.get('language') || undefined,
      organization_id:
        organizationId !== 'ALL_ORGS' ? organizationId : undefined,
      name: query.get('name') || undefined,
    },
    sorter: {
      order: query.get('order') ?? 'desc',
      sort: query.get('sort') ?? 'created_at',
    },
    pagination: {
      page: query.get('page') ?? 1,
      per: PLANS_PER_PAGE,
    },
  })

  const isFilterPresent = useMemo(
    () =>
      Boolean(query.get('name') || query.get('page') || query.get('language')),
    [query],
  )

  // Sometimes organizationId is null until organizations data is fetched!
  if (organizationId === null) {
    return <Loader />
  }

  return (
    <div className={styles.plansContainer}>
      <PlansHeading isViewingSingleOrg={!isViewingAllOrgs} />
      {plansResponse?.data.length === 0 && !isFilterPresent ? (
        <NewOrganization isViewingSingleOrg={!isViewingAllOrgs} />
      ) : (
        <LandingPage
          data={plansResponse}
          loadingStatus={status}
          organizationId={organizationId}
        />
      )}
    </div>
  )
}

const stateToProps = (state: State): PlansComponentProps => ({
  organizationId: activeOrgIdSelector(state),
  isViewingAllOrgs: viewingAllOrgsSelector(state),
})

export default connect(stateToProps)(PlansComponent)
