/* eslint-disable camelcase, import/prefer-default-export */
import { defaultBlockTypes } from '@youversion/mui-block-editor'
import { Plan } from 'components/Plans/types'
import i18n from 'helpers/i18n'

type MuiBlockType = keyof typeof defaultBlockTypes

export type BlockError = {
  blockId?: string
  type?: string
  content?: string
  contentEmpty?: string
  fileUploadFailed?: string
  fileMissing?: string
  filePending?: string
}

/**
 * Validates devotional content blocks.
 *
 * @param {Array<ValidBlockType>} blocks - The array of blocks to validate.
 *
 * @returns {Array<BlockError>} - An array of errors.
 */
export function validateBlocks(blocks: Array<Plan.DevotionalContentBlocks>) {
  const { t } = i18n
  const errors: Array<{ blockId: string; blockErrors: BlockError }> = []
  const validBlockTypes = Object.keys(defaultBlockTypes).map((blockType) => {
    if (!defaultBlockTypes[blockType as MuiBlockType].name) {
      throw new Error(
        `defaultBlockTypes.${blockType}.name property is missing or empty.`,
      )
    }

    return defaultBlockTypes[blockType as MuiBlockType].name
  })

  if (blocks?.length) {
    blocks.forEach((block) => {
      const blockErrors: BlockError = {}
      const {
        block_id: blockId,
        content: blockContent,
        type: blockType,
      } = block

      if (!blockId || typeof blockId !== 'string') {
        blockErrors.blockId = t('plan_days:validation.missing_block_id')
      }

      if (
        !blockType ||
        typeof blockType !== 'string' ||
        !validBlockTypes.includes(blockType)
      ) {
        blockErrors.type = t('plan_days:validation.missing_block_type')
      }

      if (
        !blockContent ||
        typeof blockContent !== 'object' ||
        Array.isArray(blockContent)
      ) {
        blockErrors.content = t('plan_days:validation.missing_block_content')
      }

      // Validate empty text block.
      if (
        blockType === 'text' &&
        (!blockContent ||
          !blockContent.html ||
          blockContent.html === '<p><br></p>' ||
          blockContent.html === '<p> </p>' ||
          blockContent.html === '<p>&nbsp;</p>')
      ) {
        blockErrors.contentEmpty = t('plan_days:validation.empty_text_block')
      }

      // Validate against blank or too-short YouTube Video ID.
      if (
        blockType === 'youtube_video' &&
        (!blockContent ||
          !blockContent.youtube_video_id ||
          blockContent.youtube_video_id.length < 11)
      ) {
        blockErrors.content = t('plan_days:validation.invalid_url')
      }

      if (
        blockType === 'audio' ||
        blockType === 'yv_video' ||
        blockType === 'video' ||
        blockType === 'image'
      ) {
        if (blockContent?.error) {
          blockErrors.fileUploadFailed = t(
            'plan_days:validation.file_upload_fail',
          )
        } else if (
          !blockContent?.file_id &&
          !blockContent?.source_url &&
          !blockContent?.error
        ) {
          blockErrors.fileMissing = t('plan_days:validation.missing_file')
        } else if (
          blockContent?.file_id &&
          !blockContent?.source_url &&
          !blockContent?.error
        ) {
          blockErrors.filePending = t('plan_days:validation.file_pending')
        }
      }

      if (Object.keys(blockErrors).length) {
        errors.push({
          blockId: block.block_id ? block.block_id : 'id_unknown',
          blockErrors,
        })
      }
    })
  }

  return errors
}
