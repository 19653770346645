/* eslint-disable jsdoc/require-param */
/**
 * @module PlanBox
 */
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Collapse,
  Grid,
  Typography,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import EditIcon from '@mui/icons-material/Edit'
import { OpenInNew } from '@mui/icons-material'
import { AsyncButton } from '@youversion/react'
import classnames from 'classnames'
import moment from 'moment'
import { Can, useAuth } from 'auth'
import { CommentSection } from 'components/Card'
import { API_STATUS, submissionStatuses } from 'helpers/constants'
import Divider from 'components/Divider/Divider'
import { useGetComments } from 'api/plans'
import { useTranslation } from 'react-i18next'
import styles from '../Plans.module.scss'
import CompletionChecklist from '../CompletionChecklist'
import { Plan } from '../types'
import { Organization } from '../../Organizations/types'

const useStyles = makeStyles((theme) =>
  createStyles({
    cardSection: {
      border: 'none',
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.spacing(2),
      position: 'relative',
    },
    editIcon: {
      position: 'absolute',
      insetInlineEnd: theme.spacing(1),
      insetBlockStart: theme.spacing(1),
    },
    fullWidth: {
      width: '100%',
    },
    planBox: {
      border: 'none',
      borderRadius: theme.spacing(2),
      gap: 16,
      height: '100%',
      width: '100%',
    },
    planContainer: {
      [theme.breakpoints.down('md')]: {
        gap: theme.spacing(4),
      },
    },
    // This will only show the scrollbars whenever content has overflowed.
    // See https://www.bennadel.com/blog/3642-use-css-overflow-auto-not-overflow-scroll-when-clipping-most-fixed-size-containers.htm
    scrollHorizontally: {
      overflowX: 'auto',
      overflowY: 'hidden',
    },
  }),
)

interface PlanBoxProps {
  /** The plan info approval handler. */
  onApprovePlanInfo: VoidFunction
  /** The user's profile image url. */
  ownAvatarUrl: string
  /** Plan Object. */
  plan: Plan.Plan
  planDays: Plan.Day[]
  primaryOrganization: Organization.Organization
}

/**
 * The PlanBox component.
 
 * @returns {React.ReactElement} - The PlanBox component.
 */
export default function PlanBox({
  onApprovePlanInfo,
  plan,
  planDays,
  primaryOrganization,
}: PlanBoxProps) {
  const classes = useStyles()
  const { t, i18n } = useTranslation(['plans', 'common'])

  const { user: loggedInUser } = useAuth()

  const {
    data: comments,
    refetch: refetchComments,
    status: commentLoadingStatus,
  } = useGetComments({
    planId: plan.id,
  })

  const isDoneLoading = commentLoadingStatus === API_STATUS.SUCCESS

  // const targetLaunch = moment(plan.targetLaunch).locale(i18n.language).format('dddd, MMMM Do YYYY');

  return (
    <Collapse className={classes.fullWidth} in={Boolean(plan && isDoneLoading)}>
      <Box
        className={classnames(styles.planBoxFull, classes.planBox)}
        data-testid="plan-box-container"
        id="plan-box"
      >
        <Grid className={classes.planContainer} container={true} spacing={2}>
          <Grid item={true} lg={6} xs={12}>
            <Card className={classes.cardSection} variant="outlined">
              <CardContent>
                <Box
                  display="flex"
                  flexGrow={1}
                  justifyContent="space-between"
                  mb={2}
                >
                  <Typography variant="h2">
                    {t('plans:plan_information')}
                  </Typography>
                  <Box alignItems="center" display="flex" flexDirection="row">
                    <Can user="edit:plan_submission">
                      <Box mr={1}>
                        {plan?.status === submissionStatuses.SUBMITTED ? (
                          <AsyncButton
                            idle={t('plans:approve_plan.approve')}
                            onClick={onApprovePlanInfo}
                            options={{
                              disableUpdateOnSuccess: true,
                            }}
                            pending={t('plans:approve_plan.approve')}
                            rejected={t('plans:day.info_error')}
                            variant="contained"
                          />
                        ) : null}
                      </Box>
                    </Can>
                    <Button
                      component={Link}
                      size="small"
                      startIcon={<EditIcon fontSize="inherit" />}
                      to="edit"
                      variant="contained"
                    >
                      {t('common:edit')}
                    </Button>
                  </Box>
                </Box>

                {plan.status === submissionStatuses.DRAFT ? (
                  <Box mb={2}>
                    <CompletionChecklist plan={plan} planDays={planDays} />
                  </Box>
                ) : null}

                {plan.description ? (
                  <Box mb={2}>
                    <Typography variant="h3">
                      {t('plans:plan_description')}
                    </Typography>
                    <Typography color="textSecondary" variant="body1">
                      {plan.description}
                    </Typography>
                  </Box>
                ) : null}

                {plan.launches_at ? (
                  <Box mb={2}>
                    <Typography variant="h3">
                      {t('plans:plan_launch_date')}
                    </Typography>
                    <Typography color="textSecondary" variant="body1">
                      {moment(plan.launches_at)
                        .locale(i18n.language)
                        .format('l')}
                    </Typography>
                  </Box>
                ) : null}

                {plan.submitted_at ? (
                  <Box mb={2}>
                    <Typography variant="h3">
                      {t('plans:plan_last_submitted')}
                    </Typography>
                    <Typography color="textSecondary" variant="body1">
                      {plan.submitted_at
                        ? moment(plan.submitted_at)
                            .locale(i18n.language)
                            .format('l')
                        : t('plans:not_submitted')}
                    </Typography>
                  </Box>
                ) : null}

                {plan.categories?.length ? (
                  <Box mb={2}>
                    <Typography variant="h3">
                      {t('plans:plan_categories')}
                    </Typography>
                    <Box
                      className={classes.scrollHorizontally}
                      display="flex"
                      width="100%"
                    >
                      {plan.categories.map((category) => (
                        <Box display="span" key={category} mr={1}>
                          <Chip
                            label={t(`plans:categories.${category}`)}
                            size="small"
                            variant="outlined"
                          />
                        </Box>
                      ))}
                    </Box>
                  </Box>
                ) : null}

                {plan.keywords?.length ? (
                  <Box mb={2}>
                    <Typography variant="h3">
                      {t('plans:plan_keywords')}
                    </Typography>
                    <Box
                      className={classes.scrollHorizontally}
                      display="flex"
                      width="100%"
                    >
                      {plan.keywords.map((keyword) => (
                        <Box display="span" key={keyword} mr={1}>
                          <Chip
                            label={keyword}
                            size="small"
                            variant="outlined"
                          />
                        </Box>
                      ))}
                    </Box>
                  </Box>
                ) : null}

                <Divider />

                {plan.external_plan_url ? (
                  <Box mb={2} mt={2}>
                    <Button
                      color="primary"
                      component="a"
                      endIcon={<OpenInNew />}
                      href={plan.external_plan_url}
                      rel="noopener noreferrer"
                      size="small"
                      target="_blank"
                      variant="outlined"
                    >
                      {t('plans:see_plan_on_bible_dot_com')}
                    </Button>
                  </Box>
                ) : null}

                {plan.partner_url ? (
                  <Box mb={2} mt={2}>
                    <Button
                      color="primary"
                      component="a"
                      endIcon={<OpenInNew />}
                      href={plan.partner_url}
                      rel="noopener noreferrer"
                      size="small"
                      target="_blank"
                      variant="outlined"
                    >
                      {t('plans:visit_partner_site')}
                    </Button>
                  </Box>
                ) : null}

                {plan.external_plan_id ? (
                  <Box mb={2} mt={2}>
                    <Button
                      color="primary"
                      component="a"
                      endIcon={<OpenInNew />}
                      href={`https://cassi.thewardro.be/${plan.external_plan_id}`}
                      rel="noopener noreferrer"
                      size="small"
                      target="_blank"
                      variant="outlined"
                    >
                      {t('plans:see_plan_on_cassi')}
                    </Button>
                  </Box>
                ) : null}

                <Divider />

                {primaryOrganization?.name ? (
                  <Box mb={2}>
                    <Typography variant="h3">
                      {t('plans:organization_author')}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      component={Link}
                      to={`/organizations/${primaryOrganization.id}`}
                      variant="body1"
                    >
                      {primaryOrganization.name}
                    </Typography>
                  </Box>
                ) : null}

                {plan.creator ? (
                  <Box mb={2}>
                    <Typography variant="h3">
                      {t('plans:created_by')}
                    </Typography>
                    <Typography color="textSecondary" variant="body1">
                      {`${plan.creator.first_name} ${plan.creator.last_name} (${plan.creator.email})`}
                    </Typography>
                  </Box>
                ) : null}

                {primaryOrganization?.staff_representative ? (
                  <Box mb={2}>
                    <Typography variant="h3">
                      {t('plans:staff_representative')}
                    </Typography>
                    <Typography color="textSecondary" variant="body1">
                      {`${primaryOrganization.staff_representative.first_name} ${primaryOrganization.staff_representative.last_name} (${primaryOrganization.staff_representative.email})`}
                    </Typography>
                  </Box>
                ) : null}

                {plan.last_edited_by_org_user ? (
                  <Box mb={2}>
                    <Typography variant="h3">
                      {t('plans:last_organization_editor')}
                    </Typography>
                    <Typography color="textSecondary" variant="body1">
                      {`${plan.last_edited_by_org_user.first_name} ${plan.last_edited_by_org_user.last_name} (${plan.last_edited_by_org_user.email})`}
                    </Typography>
                  </Box>
                ) : null}

                {loggedInUser?.role === 'admin' && plan?.last_edited_by ? (
                  <Box mb={2}>
                    <Typography variant="h3">
                      {t('plans:last_edited_by')}
                    </Typography>
                    <Typography color="textSecondary" variant="body1">
                      {`${plan.last_edited_by.first_name} ${plan.last_edited_by.last_name} (${plan.last_edited_by.email})`}
                    </Typography>
                  </Box>
                ) : null}
              </CardContent>
            </Card>
          </Grid>

          {comments ? (
            <Grid item={true} lg={6} xs={12}>
              <CommentSection
                comments={comments}
                planId={plan.id}
                refetch={refetchComments}
              />
            </Grid>
          ) : null}
        </Grid>
      </Box>
    </Collapse>
  )
}

PlanBox.propTypes = {
  onApprovePlanInfo: PropTypes.func.isRequired,
  ownAvatarUrl: PropTypes.string,
}

PlanBox.defaultProps = {
  ownAvatarUrl: null,
  plan: null,
}
